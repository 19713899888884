a {
  text-decoration: none !important;
}

footer {
  font-size: smaller !important;
}

footer a {
  font-weight: normal !important;
}
/*# sourceMappingURL=index.6d04ac2a.css.map */
